@font-face {
  font-family: "Plus Jakarta Sans";
  src: url('../../assets/fonts/plus-jakarta-sans/PlusJakartaSans-Regular.woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url('../../assets/fonts/plus-jakarta-sans/PlusJakartaSans-Medium.woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url('../../assets/fonts/plus-jakarta-sans/PlusJakartaSans-SemiBold.woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url('../../assets/fonts/plus-jakarta-sans/PlusJakartaSans-Bold.woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gazpacho";
  src: url('../../assets/fonts/gazpacho/Gazpacho Black.woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Clash Display";
  src: url('../../assets/fonts/clash-display/ClashDisplay-Medium.woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Clash Display";
  src: url('../../assets/fonts/clash-display/ClashDisplay-SemiBold.woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url('../../assets/fonts/inter/Inter-SemiBold.woff2');
  font-weight: 600;
  font-style: normal;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.8;
}

html, body, #root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.App {
  text-align: center;
  min-height: 100vh;
  background-color: #ffffff;
}

.App-content {
  min-height: 88vh;
  margin-bottom: 30px;
}